body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-toggle {
  /* display: none; */
  background-color: transparent !important;
  border: none !important
}

/* styles.css */

.custom-dropdown {
  padding: 0.5rem 0;
  border-radius: 0.25rem;
}

.custom-dropdown-item {
  padding: 0.5rem 1rem;
  line-height: 1.5rem;
}

.btn-close {
  background-image: url('../src/assets/images/close.png') !important;
  background-size: 1.5rem !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.btn-close:focus {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}